import { changeColorOnVisible } from 'hooks/changeColorOnScroll';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactVisibilitySensor from 'react-visibility-sensor';

const ShootingHomeSection = () => {
  const { t } = useTranslation();
  const title = t('service.shooting.title');

  const [isSlideVisible, setIsSlideVisible] = useState(false);
  const [isSlideSeen, setIsSlideSeen] = useState(false);
  const disptach = useDispatch();
  const { backgroundColor } = useSelector(({ home }) => home);

  useEffect(() => {
    changeColorOnVisible('#f5eea5', isSlideVisible, disptach);
    if (!isSlideSeen && isSlideVisible) setIsSlideSeen(true);
    // eslint-disable-next-line
  }, [isSlideVisible]);

  return (
    <ReactVisibilitySensor onChange={setIsSlideVisible} partialVisibility>
      {() => (
        <>
          <div className="d-flex position-relative justify-content-center flex-column min-vh-100">
            <img
              src="/assets/images/shooting/animated-shoot.gif"
              alt="art"
              data-aos="fade-left"
              data-aos-duration="1000"
              className="position-absolute w-20 top-0 d-none d-md-block right-0"
            />
            <img
              src="/assets/images/shooting/left-shoot.png"
              data-aos-duration="1000"
              data-aos="fade-right"
              alt="art"
              className="position-absolute w-20 bottom-0 left-0 d-none d-md-block"
            />
            <div className="container text-justify space-bottom-md-4 space-bottom-3 position-relative">
              <ReactVisibilitySensor onChange={setIsSlideVisible} partialVisibility>
                {() => (
                  <div className="d-flex align-items-center justify-content-center mb-md-11  mt-11">
                    <span
                      className={`display-1  text-danger text-${
                        backgroundColor === '#f5eea5' ? 'danger' : 'white'
                      } position-relative p-0`}
                      data-letters={title}
                    >
                      {title}
                    </span>
                  </div>
                )}
              </ReactVisibilitySensor>
              <img
                src="/assets/images/shooting/animated-shoot.gif"
                alt="art"
                data-aos="fade-up"
                className="w-100 d-block d-md-none"
                data-aos-duration="1000"
              />
              <div
                className={`letter-effect font-size-3 text-${
                  backgroundColor === '#f5eea5' ? 'danger' : 'white'
                } font-weight-normal`}
              >
                {t('service.shooting.1')}
              </div>
              <div
                className={`letter-effect font-size-3 text-${
                  backgroundColor === '#f5eea5' ? 'danger' : 'white'
                } font-weight-normal`}
              >
                {t('service.shooting.2')}
              </div>
              <div
                className={`letter-effect font-size-3 text-${
                  backgroundColor === '#f5eea5' ? 'danger' : 'white'
                } font-weight-normal`}
              >
                {t('service.shooting.3')}
              </div>
              <Link
                to="/shooting"
                className={`align-self-end continue-reading text-${backgroundColor === '#f5eea5' ? 'danger' : 'white'}`}
              >
                {t('seeMore')}
              </Link>
            </div>
          </div>
        </>
      )}
    </ReactVisibilitySensor>
  );
};

export default ShootingHomeSection;
